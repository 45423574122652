
import { CustomTag } from '@src/components/CustomTag'
import { TagStyle } from '@src/types'

import styles from './styles.module.scss'

export interface PricingItemProps {
  type: TagStyle
  heading: string
  contents: { title: string; value: string }[]
}

export const PricingItem = ({ type, heading, contents }: PricingItemProps) => {
  return (
    <div className={styles.pricing_item}>
      <div className={styles.pricing_item_heading}>
        <CustomTag text={type.text} bgColor={type.bgColor} color={type.color} />
        <div className="subtitle2 tw-ml-8">{heading}</div>
      </div>
      <div className={`${styles.pricing_contents} background-base`}>
        {contents?.map((content, index) => {
          return (
            <div key={index} className="primary txt-center">
              <div className="body2">{content.title}</div>
              <div className="subtitle3">{content.value}</div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
