import { useEffect, useState } from 'react'

import { closeWebView } from '@socar-inc/web-frontend-libraries'
import { useRouter } from 'next/router'

import { ButtonFloating } from '@src/components'
import useGuideUpdateBaseQuery from '@src/hooks/mutations/useGuideConfirmUpdateBaseQuery'
import { useListenerEvent } from '@src/hooks/useListenerEvent'
import lokalise from '@src/libs/locales/ko/ko.json'
import { moveToCloseResultOk } from '@src/utils/moveToApp/moveToCloseResultOk'

export const GuideFloatingButton = () => {
  const router = useRouter()
  const { updateGuideConfirm } = useGuideUpdateBaseQuery({
    onSuccess: () => {
      moveToCloseResultOk()
    },
  })

  const [isDisabled, setIsDisabled] = useState(true)
  const firstEnter = router?.query?.is_checked === 'false'
  const handleScoroll = () => {
    const scrollY = document.getElementsByTagName('html')[0].scrollTop
    const disabledHedight = 500

    if (scrollY > disabledHedight) {
      if (isDisabled) setIsDisabled(false)
    }
  }

  const onClickGuideConfirm = () => {
    if (firstEnter) {
      updateGuideConfirm()
    } else {
      closeWebView()
    }
  }

  useEffect(() => {
    setIsDisabled(firstEnter)
  }, [firstEnter])

  useListenerEvent('scroll', handleScoroll)

  return (
    <ButtonFloating
      bgColor="white"
      text={lokalise.elecle_guide_button}
      disabled={isDisabled}
      onClickCapture={onClickGuideConfirm}
    />
  )
}
