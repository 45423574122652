import { useEffect, useRef } from 'react'

import { closeWebView } from '@socar-inc/web-frontend-libraries'

import useMemberDetailBaseQuery from '@src/hooks/queries/useMemberDetailBaseQuery'

interface InitChatbotProps {
  isReady: boolean
  member_id?: string | string[]
  riding_id?: string | string[]
}

export const useChatBot = ({
  isReady,
  member_id,
  riding_id,
}: InitChatbotProps) => {
  const { memberInfo } = useMemberDetailBaseQuery({
    enabled: member_id ? false : true,
  })

  useEffect(() => {
    const hasMemberId = member_id || memberInfo?.member.id
    if (isReady && hasMemberId) {
      init()
    }
  }, [isReady, member_id, riding_id, memberInfo?.member.id])

  const init = () => {
    if (!Twc) return
    if (!(typeof Twc === 'function')) return

    const initOptions = {
      memberId: member_id || memberInfo?.member.id,
      brandKey: process.env.NEXT_PUBLIC_CHAT_BRAND_KEY,
      channelType: process.env.NEXT_PUBLIC_CHAT_CHANNEL_TYPE,
      scenarioId: process.env.NEXT_PUBLIC_CHAT_SCENARIOD_ID,
      buttonOption: {
        showLauncher: false,
      },
      openMode: 'frame',
    }

    const initRidingOptions = {
      ...initOptions,
      ridingId: riding_id,
    }

    Twc('init', riding_id ? initRidingOptions : initOptions, setChatBotWebView)
  }

  const setChatBotWebView = () => {
    if (!Twc) return
    if (!(typeof Twc === 'object')) return
    // 네트워크가 느린 경우에 iframe이 생기지 않아,
    // 0.1초후 iframe이 만들어졌는지 다시 체크해 쳇봇 오픈 시도를 합니다.
    const iframe = document.getElementById('chat-frame')
    if (!iframe) {
      setTimeout(setChatBotWebView, 100)
    }

    Twc?.Chat?.open()
  }

  const onChatOpen = () => {
    if (!Twc) return
    if (!(typeof Twc === 'object')) return
    Twc?.Chat?.open()
  }

  const confirmClose = () => {
    if (!Twc) return
    if (!(typeof Twc === 'object')) return

    if (confirm('상담을 종료하시겠습니까?')) {
      Twc.Chat.conclude()
      // 상담 종료 후 웹뷰가 바로 닫히면, 이용내역이 저장되지 않아 0.5초 딜레이를 주고 웹뷰를 닫습니다.
      setTimeout(closeWebView, 1000)
    }
  }

  return {
    confirmClose,
  }
}
