import { Navigation, UnitStyle } from '@socar-inc/frontend-design-system'
import { closeWebView } from '@socar-inc/web-frontend-libraries'
import { useRouter } from 'next/router'

import {
  ACTION_BAR_HEIGHT,
  GUIDE_CONTENTS,
  GUIDE_CONTENT_BIKE,
} from '@src/constants'
import { useHideActionBar } from '@src/hooks/useHideActionBar'
import lokalise from '@src/libs/locales/ko/ko.json'
import { moveToCloseResultCancel } from '@src/utils/moveToApp/moveToCloseResultCancel'

import { GuideFloatingButton } from './GuideFloatingButton'
import { GuideNotice } from './GuideNotice'
import { GuideSection } from './GuideSection'
import { GuideSectionBike } from './GuideSectionBike'
import styles from './styles.module.scss'

export const Guide = () => {
  const router = useRouter()
  const firstEnter = router?.query?.is_checked === 'false'

  useHideActionBar()
  return (
    <div className={`${styles.guide_wrapper} tw-pt-44`}>
      <Navigation
        showPageTitle
        pageName={lokalise.elecle_guide_title}
        rightUnit1={{
          unitStyle: UnitStyle.CLOSE,
          clickFunction: () => {
            firstEnter ? moveToCloseResultCancel() : closeWebView()
          },
        }}
        navHeight={ACTION_BAR_HEIGHT}
        showBackButton={false}
      />

      <GuideSectionBike
        header={GUIDE_CONTENT_BIKE.header}
        body={GUIDE_CONTENT_BIKE.body}
        subtitle={GUIDE_CONTENT_BIKE.subtitle}
        guideImage={GUIDE_CONTENT_BIKE.guideImage}
        textColor="white"
      />
      {GUIDE_CONTENTS?.map((content, index) => {
        return <GuideSection {...content} key={index} />
      })}
      <GuideNotice />
      <GuideFloatingButton />
    </div>
  )
}
