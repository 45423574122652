import { useEffect } from 'react'

export const useListenerEvent = (eventType: string, listener: any) => {
  useEffect(() => {
    window.addEventListener(eventType, listener)
    return () => {
      window.removeEventListener(eventType, listener)
    }
  }, [eventType, listener])
}
