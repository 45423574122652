import { BulletNotice } from '@src/components/BulletNotice'

import styles from './styles.module.scss'

export interface GuideSectionProps {
  header?: string
  subtitle?: string
  body?: string
  noticeBody?: string[]
  textColor?: string
  guideImage?: {
    path: string
    alt: string
  }
  className?: string
}

export const GuideSection = ({
  header,
  subtitle,
  body,
  noticeBody,
  guideImage,
  textColor = 'grey060',
  className = '',
}: GuideSectionProps) => {
  return (
    <section className={`${styles.guide_section} ${styles[className]}`}>
      <div
        className={`${styles.guide} tw-text-${textColor} tw-flex tw-gap-8 tw-flex-col`}>
        {header && <div className={`${styles.header} header1`}>{header}</div>}
        {subtitle && <div className="subtitle3">{subtitle}</div>}
        {body && <div className={`body2 ${styles.body}`}>{body}</div>}
        {noticeBody && <BulletNotice items={noticeBody} />}
        {guideImage ? (
          <img src={guideImage?.path} alt={guideImage?.alt} />
        ) : null}
      </div>
    </section>
  )
}
