import { Icon } from '@socar-inc/frontend-design-system'
import { useRouter } from 'next/router'

import { Stack } from '@src/components'
import { ButtonFixedBottom } from '@src/components/ButtonFixedBottom'
import lokalize from '@src/libs/locales/ko/ko.json'
import { PermissionEnum, bridgeRequestPermissions } from '@src/utils/bridge'
import { isAOS } from '@src/utils/userAgent'

export const PermissionView = () => {
  const router = useRouter()

  const handleGetPermission = () => {
    bridgeRequestPermissions({
      data: {
        requestPermissions: isAOS()
          ? [
              PermissionEnum.LOCATION,
              PermissionEnum.CAMERA,
              PermissionEnum.PHOTO,
            ]
          : [PermissionEnum.LOCATION, PermissionEnum.CAMERA],
      },
      callback: () => router.push('/guide?is_checked=false'),
    })
  }

  const NECESSARY_PERMISSIONS = [
    {
      title: lokalize.location,
      description: lokalize.elecle_access_right_location_description,
    },
    {
      title: lokalize.camera,
      description: lokalize.elecle_access_right_camera_description,
    },
    isAOS() // Android만 저장공간 권한이 필요함
      ? {
          title: lokalize.storage_space,
          description: lokalize.elecle_access_right_storage_description,
        }
      : null,
  ]

  return (
    <>
      <Stack my={42} mx={24} spacing={22} className="tw-text-grey060">
        <div>
          <div
            className="header1 top-8px bottom-8px"
            style={{ position: 'relative' }}>
            {lokalize.title_socar_authority}
          </div>
          <div className="body2">
            {lokalize.elecle_access_right_description}
          </div>
        </div>

        <Stack spacing={16}>
          <div className={'tw-text-blue060 subtitle3'}>
            {lokalize.access_right_essential}
          </div>
          {NECESSARY_PERMISSIONS.map((permission, index) => {
            if (permission) {
              return (
                <Stack direction="row" spacing={8} key={index} mt={8}>
                  <Stack direction="row">
                    <Icon icon="ic18_description_dash2" color="grey050" />
                    <div className={'primary tw-w-[64px] tw-font-semibold'}>
                      {permission.title}
                    </div>
                  </Stack>
                  <div className="secondary">{permission.description}</div>
                </Stack>
              )
            }
          })}
        </Stack>
      </Stack>
      <ButtonFixedBottom text="계속하기" onClick={handleGetPermission} />
    </>
  )
}
