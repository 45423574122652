import { UnitStyle } from '@socar-inc/frontend-design-system'

import { WithNavigationContainer } from '@src/containers'
import lokalise from '@src/libs/locales/ko/ko.json'
import { moveToCloseResultCancel } from '@src/utils'

import { PermissionView } from './PermissionView'

export const Permission = () => {
  return (
    <WithNavigationContainer
      navStyle="line"
      navProps={{
        pageName: lokalise.title_socar_authority,
        rightUnit1: {
          unitStyle: UnitStyle.CLOSE,
          clickFunction: moveToCloseResultCancel,
        },
        showBackButton: false,
      }}>
      <PermissionView />
    </WithNavigationContainer>
  )
}
