import { dehydrate, QueryClient } from '@tanstack/react-query'
import type { NextPage } from 'next'

import { Main } from 'src/views'

export const getServerSideProps = async () => {
  const queryClient = new QueryClient()

  return {
    props: {
      dehydratedState: dehydrate(queryClient),
    },
  }
}

const MainPage: NextPage = () => <Main />

export default MainPage
