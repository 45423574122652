import { UseQueryOptions } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { getMemberDetails } from '@src/apis'
import { memberQueryKeys } from '@src/stores'
import type { GetMemberResult } from '@src/types/memberType'

import { useCustomQuery } from '../useCustomQuery'

const useMemberDetailBaseQuery = (
  options?: UseQueryOptions<GetMemberResult, AxiosError>
) => {
  const {
    data: memberInfo,
    isLoading,
    error,
    refetch: refetchMemberInfo,
  } = useCustomQuery<GetMemberResult>(
    memberQueryKeys.memberDetail,
    () => getMemberDetails(),
    options
  )

  return {
    memberInfo,
    isLoading,
    error,
    refetchMemberInfo,
  }
}

export default useMemberDetailBaseQuery
