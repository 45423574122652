import { Color } from '@src/types'

export interface CustomTagProps {
  text: string
  bgColor: Color
  color: Color
  borderRadius?: 2 | 4
  textName?: 'caption1' | 'subtitle4'
}

/**
 * @description CustomTag 컴포넌트는 태그를 표시할 때 사용합니다.
 * @param text 태그에 표시할 텍스트
 * @param bgColor 태그의 배경색
 * @param color 태그의 글자색
 * @param borderRadius 태그의 둥근 정도 (기본값: 2)
 * @param textName 태그의 텍스트 클래스 이름 (기본값: caption1)
 * @example
 * <CustomTag text="tag" color="coral060" bgColor="coral000" />
 */
export const CustomTag = ({
  text,
  bgColor,
  color,
  borderRadius = 2,
  textName = 'caption1',
}: CustomTagProps) => {
  const borderRadiusVariants = {
    2: 'tw-rounded-[2px]',
    4: 'tw-rounded-[4px]',
  }

  return (
    <div
      className={`tw-text-${color} tw-shrink-0 tw-bg-${bgColor} ${borderRadiusVariants[borderRadius]} ${textName} tw-inline-block tw-min-w-37 tw-px-[8px] tw-py-[3px] tw-text-center`}>
      {text}
    </div>
  )
}
