import lokalise from '@src/libs/locales/ko/ko.json'

import { GuideSection, GuideSectionProps } from './GuideSection'
import styles from './styles.module.scss'

export const GuideSectionBike = ({
  header,
  subtitle,
  body,
  guideImage,
}: GuideSectionProps) => {
  return (
    <div className="guide_bike">
      <div className={`${styles.guide_bike_bg} tw-bg-blue040`}></div>
      <GuideSection
        header={lokalise.elecle_guide_title}
        body={lokalise.elecle_guide_description}
        textColor="white"
        className="title_section"
      />

      <GuideSection
        header={header}
        subtitle={subtitle}
        body={body}
        guideImage={guideImage}
        textColor="white"
      />
    </div>
  )
}
