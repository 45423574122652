import { Icon } from '@socar-inc/frontend-design-system'

import styles from './styles.module.scss'

interface NoticeProps {
  items: string[]
  textType?: 'body2' | 'caption1'
  textColor?: 'primary' | 'grey050'
}

export const BulletNotice = ({
  items,
  textType = 'body2',
  textColor = 'primary',
}: NoticeProps) => {
  return (
    <div>
      {items.map((description, index) => {
        return (
          <section className={`${styles.notice_description}`} key={index}>
            <Icon
              className="description-icon"
              icon="ic18_description_dash2"
              color="grey045"
            />
            <p className={`${textType} tw=text-${textColor}`}>{description}</p>
          </section>
        )
      })}
    </div>
  )
}
