import { UseQueryOptions } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { getPricingList } from '@src/apis'
import { pricingQueryKeys } from '@src/stores/react-query/pricingQueryKeys'
import type { GetPricingParams, GetpricingResult } from '@src/types/pricingType'

import { useCustomQuery } from '../useCustomQuery'

const usePricingListBaseQuery = (
  params: GetPricingParams,
  options?: UseQueryOptions<GetpricingResult, AxiosError>
) => {
  const hasBikeSn = String(params?.bikeSn) !== 'undefined'
  const hasRidingId = String(params?.ridingId) !== 'undefined'

  const {
    data: pricingList,
    isLoading,
    error,
    refetch: refetchPricingList,
  } = useCustomQuery<GetpricingResult>(
    pricingQueryKeys.pricingList(params),
    () => getPricingList(params),
    {
      enabled: hasBikeSn || hasRidingId,
      ...options,
    }
  )

  return {
    pricingList,
    isLoading,
    error,
    refetchPricingList,
  }
}

export default usePricingListBaseQuery
