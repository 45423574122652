import { useState } from 'react'

import { Loading } from '@socar-inc/frontend-design-system'
import Script from 'next/script'

import { useHideActionBar } from '@src/hooks/useHideActionBar'

import { ChatbotNavigator } from './ChatbotNavigator'

export default function ChatBot() {
  const [isReady, setIsReady] = useState(false)

  useHideActionBar({
    subscription: [isReady],
    retentionSite: ['about:blank', 'https://webchat.thecloudgate.io', '/'],
  })

  const setChatbotReady = () => {
    setIsReady(true)
  }

  return (
    <div>
      <Loading style={{ zIndex: -1 }} />
      <Script
        src="https://public-common-sdk.s3.ap-northeast-2.amazonaws.com/sdk/cloud/Twc.plugin.js"
        onReady={setChatbotReady}></Script>
      <ChatbotNavigator isReady={isReady} />
    </div>
  )
}
