import { Navigation, UnitStyle } from '@socar-inc/frontend-design-system'
import { closeWebView } from '@socar-inc/web-frontend-libraries'
import { useRouter } from 'next/router'

import usePricingListBaseQuery from '@src/hooks/queries/usePricingListBaseQuery'
import { useHideActionBar } from '@src/hooks/useHideActionBar'
import lokalise from '@src/libs/locales/ko/ko.json'

import { PricingItem } from './PricingItem'
import styles from './styles.module.scss'

const Pricing = () => {
  const router = useRouter()
  const { bike_sn, riding_id } = router.query
  const { pricingList } = usePricingListBaseQuery({
    bikeSn: bike_sn as string,
    ridingId: riding_id as string,
  })

  useHideActionBar()

  return (
    <div className={styles.pricing_container}>
      <Navigation
        showPageTitle
        pageName={''}
        rightUnit1={{
          unitStyle: UnitStyle.CLOSE,
          clickFunction: closeWebView,
        }}
        navHeight={44}
        showBackButton={false}
      />
      <div className={`${styles.pricing_board}`}>
        <div className="pricing_text">
          <div className="subtitle1 tw-mb-8 primary">
            {lokalise.elecle_price_guide_view_title}
          </div>
          <div className="body2 secondary">
            {lokalise.elecle_price_guide_view_body}
          </div>
        </div>
      </div>
      {pricingList?.map((item) => {
        return <PricingItem {...item} key={item.heading} />
      })}
    </div>
  )
}

export default Pricing
