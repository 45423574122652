import { MutationOptions, useQueryClient } from '@tanstack/react-query'

import { updateGuideConfirmApi } from '@src/apis/services'

import { useCustomMutation } from '../useCustomMutation'

const useGuideUpdateBaseQuery = (options: MutationOptions = {}) => {
  const onSuccess = options?.onSuccess

  const {
    mutate: updateGuideConfirm,
    isLoading: updateGuideConfirmLoading,
    error: updateGuideConfirmError,
  } = useCustomMutation(() => updateGuideConfirmApi(), {
    ...options,
    onSuccess: (data: any, variables: void, context: unknown) => {
      onSuccess && onSuccess(data, variables, context)
    },
  })

  return {
    updateGuideConfirm,
    updateGuideConfirmLoading,
    updateGuideConfirmError,
  }
}

export default useGuideUpdateBaseQuery
