import { closeWebView } from '@socar-inc/web-frontend-libraries'
import { useRouter } from 'next/router'

import { useChatBot } from './hooks'
import styles from './styles.module.scss'

export const ChatbotNavigator = ({ isReady }: { isReady: boolean }) => {
  const router = useRouter()
  const { member_id, riding_id } = router.query
  const { confirmClose } = useChatBot({
    isReady,
    member_id,
    riding_id,
  })

  return (
    <div className={styles.chatbot_right_btns}>
      <span onClick={closeWebView} className={styles.hiddenBtn}></span>
      <span onClick={confirmClose} className={styles.closeBtn}></span>
    </div>
  )
}
